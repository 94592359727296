<script>
  import { inertia } from '@inertiajs/svelte'
  import { page } from '@inertiajs/svelte'
  import { fade } from 'svelte/transition';
  import classes from 'svelte-transition-classes';

  import { T } from "@tolgee/svelte";
  import { getLanguageStore } from "@tolgee/svelte";

  const languageStore = getLanguageStore();

  $: current_lang = ($languageStore === 'en') ? '🇺🇸' : '🇹🇼'

  let mob_visible = false

  function toggleMobMenu(e) {
    mob_visible = !mob_visible;
  }

  function hideMobMenu(e) {
    mob_visible = false;
  }
</script>

<!-- Mobile menu -->
{#if mob_visible}
<div class="fixed inset-0 flex z-40 lg:hidden" role="dialog" aria-modal="true">
  <div transition:fade={{duration: 200}} class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>

  <div
      in:classes="{{
        duration: 300,
        base: 'transition ease-in-out transform duration-300',
        from: '-translate-x-full', to: 'translate-x-0'
      }}"
      out:classes="{{
        duration: 300,
        base: 'transition ease-in-out transform duration-300',
        from: 'translate-x-0', to: '-translate-x-full'
      }}"
      class="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto"
  >
    <div class="px-4 pt-5 pb-2 flex">
      <button on:click={toggleMobMenu} type="button" class="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400">
        <span class="sr-only">Close menu</span>
        <!-- Heroicon name: outline/x -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>

    <!-- Links -->
    <div class="border-t border-gray-200 py-6 px-4 space-y-6">
      <div class="flow-root">
        <a use:inertia on:click={hideMobMenu} href="/items" class="-m-2 p-2 block font-medium text-gray-900">
          <T keyName="nav_items" defaultValue="Items" />
        </a>
      </div>

      <div class="flow-root">
        <a use:inertia on:click={hideMobMenu} href="/test_sheets" class="-m-2 p-2 block font-medium text-gray-900">
          <T keyName="nav_test_sheets" defaultValue="Test Sheets" />
        </a>
      </div>

      <div class="flow-root">
        <a use:inertia on:click={hideMobMenu} href="/schedules" class="-m-2 p-2 block font-medium text-gray-900">
          <T keyName="nav_schedules" defaultValue="Schedules" />
        </a>
      </div>

      <div class="flow-root">
        <a use:inertia on:click={hideMobMenu} href="/reports" class="-m-2 p-2 block font-medium text-gray-900">
          <T keyName="nav_reports" defaultValue="Reports" />
        </a>
      </div>
    </div>

    <div class="border-t border-gray-200 py-6 px-4 space-y-6">
      <div class="flow-root">
        {#if $page.props.auth}
          <span class="-m-2 p-2 block font-medium text-gray-900">
            {$page.props.auth.user}
          </span>
          <span class="h-6 w-px bg-gray-200" aria-hidden="true"></span>
          <button use:inertia="{{ href: '/logout', method: 'delete' }}"
                  on:click={hideMobMenu}
                  type="button"
                  class="-m-2 p-2 block font-medium text-gray-900"
          >
            <T keyName="nav_sign_out" defaultValue="Sign out" />
          </button>
        {:else}
          <a use:inertia on:click={hideMobMenu} href="/login" class="-m-2 p-2 block font-medium text-gray-900">
            <T keyName="nav_sign_in" defaultValue="Sign in" />
          </a>
        {/if}
      </div>
    </div>
  </div>
</div>
{/if}

<header class="relative overflow-hidden sticky top-0 z-10">
  <!-- Top navigation -->
  <nav aria-label="Top" class="relative z-20 bg-white bg-opacity-90 backdrop-filter backdrop-blur-xl">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="h-16 flex items-center">
        <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
        <button on:click={toggleMobMenu} type="button" class="bg-white p-2 rounded-md text-gray-400 lg:hidden">
          <span class="sr-only">Open menu</span>
          <!-- Heroicon name: outline/menu -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        <!-- Logo -->
        <div class="ml-4 flex lg:ml-0">
          <a use:inertia href="/">
            <span class="sr-only">Home</span>
            <img class="h-8 w-auto" src="https://d2fda0vykczvmk.cloudfront.net/images/logo.svg" alt="">
          </a>
        </div>

        <!-- Flyout menus -->
        <div class="hidden lg:ml-8 lg:block lg:self-stretch">
          <div class="h-full flex space-x-8">
            {#if $page.props.auth}
            <a use:inertia href="/items" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
              <T keyName="nav_items" defaultValue="Items" />
            </a>

            <a use:inertia href="/test_sheets" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
              <T keyName="nav_test_sheets" defaultValue="Test Sheets" />
            </a>

            <a use:inertia href="/schedules" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
              <T keyName="nav_schedules" defaultValue="Schedules" />
            </a>

            <a use:inertia href="/reports" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
              <T keyName="nav_reports" defaultValue="Reports" />
            </a>
            {/if}
          </div>
        </div>

        <div class="ml-auto flex items-center">
          <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
            {#if $page.props.auth}
              <span class="text-sm font-medium text-gray-700 hover:text-gray-800">
                {$page.props.auth.user}
              </span>
              <span class="h-6 w-px bg-gray-200" aria-hidden="true"></span>
              <button use:inertia="{{ href: '/logout', method: 'delete' }}"
                      type="button"
                      class="text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                <T keyName="nav_sign_out" defaultValue="Sign out" />
              </button>
            {:else}
              <a use:inertia href="/login" class="text-sm font-medium text-gray-700 hover:text-gray-800">
                <T keyName="nav_sign_in" defaultValue="Sign in" />
              </a>
            {/if}
          </div>

          <div class="ml-5 border-l border-gray-200 pl-2">
            <a href="#" class="flex items-center text-gray-500 hover:text-gray-600">
              <button class="ml-3 text-sm"> {current_lang} </button>
            </a>
          </div>
        </div>

      </div>
    </div>
  </nav>
</header>
