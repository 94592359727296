<script>
  import {inertia} from '@inertiajs/svelte'
  import { T } from "@tolgee/svelte";
  import { getLanguageStore } from "@tolgee/svelte";

  const languageStore = getLanguageStore();
</script>

<footer aria-labelledby="footer-heading" class="mt-10 bg-white">
  <h2 id="footer-heading" class="sr-only">Footer</h2>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="py-20 xl:grid xl:grid-cols-3 xl:gap-8">
      <div class="grid grid-cols-2 gap-8 xl:col-span-2">
        <div class="space-y-16 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-medium text-gray-900"><T keyName="footer_item_bank" defaultValue="Item Bank" /></h3>
            <ul role="list" class="mt-6 space-y-6">
              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_items" defaultValue="Items" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_test_sheets" defaultValue="Test Sheets" /> </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-900"><T keyName="footer_company" defaultValue="Company" /></h3>
            <ul role="list" class="mt-6 space-y-6">
              <li class="text-sm">
                <a use:inertia href="/about" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_about" defaultValue="Who we are" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_press" defaultValue="Press" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_terms" defaultValue="Terms &amp; Conditions" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_privacy" defaultValue="Privacy" /> </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="space-y-16 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-medium text-gray-900"><T keyName="footer_account" defaultValue="Account" /></h3>
            <ul role="list" class="mt-6 space-y-6">
              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_account" defaultValue="Manage Account" /> </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-sm font-medium text-gray-900"><T keyName="footer_connect" defaultValue="Connect" /></h3>
            <ul role="list" class="mt-6 space-y-6">
              <li class="text-sm">
                <a use:inertia href="/contact" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_contact" defaultValue="Contact Us" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_twitter" defaultValue="Twitter" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_instagram" defaultValue="Instagram" /> </a>
              </li>

              <li class="text-sm">
                <a href="#" class="text-gray-500 hover:text-gray-600"> <T keyName="footer_link_pinterest" defaultValue="Pinterest" /> </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-16 md:mt-16 xl:mt-0">
        <h3 class="text-md font-medium text-gray-700"><T keyName="footer_company_name" defaultValue="品學堂文化股份有限公司" /></h3>
        <address class="mt-4 text-sm text-gray-500">
          <T keyName="footer_address" defaultValue="台北市大安區敦化南路二段200巷16號7樓" /><br>
          <T keyName="footer_phone" defaultValue="T. +886 (02) 2377-8111" /><br>
          <T keyName="footer_fax" defaultValue="F. +886 (02) 2377-5811" /><br>
          <T keyName="footer_email" defaultValue="Email: services@wisdomhall.com.tw" />
        </address>
      </div>
    </div>

    <div class="border-t border-gray-200 py-10 flex justify-between">
      <p class="text-sm text-gray-500">Copyright &copy; 2022 Wisdom Hall Inc.</p>
      <div class="">
        <a href="#" class="flex items-center text-gray-500 hover:text-gray-600">
          <select bind:value={$languageStore} class="lang-selector text-sm border-none">
            <option value="en">🇺🇸 English (U.S)</option>
            <option value="zh-TW">🇹🇼 繁體中文</option>
          </select>
        </a>
      </div>
    </div>
  </div>
</footer>
