<script>
  import { page } from '@inertiajs/svelte'
  import FlashAlert from "./FlashAlert.svelte";
  import FlashNotice from "./FlashNotice.svelte";
  import FlashSuccess from "./FlashSuccess.svelte";

  let comp = undefined
  let msg = ''

  function detectFlash(has_flash) {
    if (!has_flash) return false

    if (flash.alert) {
      msg = flash.alert
      comp = FlashAlert
    }

    if (flash.notice) {
      msg = flash.notice
      comp = FlashNotice
    }

    if (flash.success) {
      msg = flash.success
      comp = FlashSuccess
    }

    return true
  }

  $: flash = $page.props.flash
  $: show = detectFlash($page.props.has_flash)

  function handleClose(e) {
    show = false
  }
</script>

{#if show}
  <svelte:component this={comp} msg={msg} handleClose={handleClose} />
{/if}
