import Layout from '../layouts/Layout.svelte'

import { createInertiaApp } from '@inertiajs/svelte'

// import '@skeletonlabs/skeleton/styles/all.css'
// import '@skeletonlabs/skeleton/styles/tailwind.css'
// import '@skeletonlabs/skeleton/styles/tokens.css'
// import '@skeletonlabs/skeleton/themes/theme-skeleton.css'
import '../stylesheets/theme.css'
import '../stylesheets/main.css'

const pages = import.meta.glob('../pages/**/*.svelte')

// replaced by InertiaCsrf.rb
// import axios from 'axios'
// const csrfToken = document.querySelector('meta[name=csrf-token]').content
// axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

// opt to explicit import + esm tree shaking
// import * as Routes from '@/utils/routes.js'
// window.Routes = Routes

createInertiaApp({
  resolve: async name => {
    const page = await pages[`../pages/${name}.svelte`]()
    // return (page.layout === undefined) ? Object.assign({layout: Layout}, page) : page
    return Object.assign({layout: Layout}, page)
  },
  setup({el, App, props}) {
    new App({target: el, props})
  },
})