<script>
  import MainNav from "../components/Layouts/MainNav.svelte";
  import Footer from "../components/Layouts/Footer.svelte";
  import FlashMessages from "../components/Layouts/FlashMessages.svelte";

  import { Modal } from '@skeletonlabs/skeleton';

  import { TolgeeProvider } from "@tolgee/svelte";
  import enLang from "../i18n/en.json"
  import twLang from "../i18n/zh-TW.json"

  const tolgeeConfig = {
    preloadFallback: true,
    staticData: {
      en: enLang,
      'zh-TW': twLang,
    },
    availableLanguages: ['en', 'zh-TW'],
    apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY
  }
</script>

<TolgeeProvider config={tolgeeConfig}>
  <div class="w-screen h-screen flex items-center justify-center" slot="loading-fallback">Loading...</div>

  <div class="bg-white">
    <MainNav/>
    <main>
      <div class="max-w-7xl mx-auto px-4 sm:px-8">
        <FlashMessages/>
      </div>

      <slot/>
    </main>
    <Footer/>
  </div>

  <Modal />

</TolgeeProvider>